import React from 'react';
import ReactDOM from 'react-dom';
import './content.css';
import DomInspector from './domInspector';
import ActionsBar from './components/ActionsBar';
import Popup from "./components/Popup";
import StylesContent from "./components/StylesContent";
import { cssSpiderRootName } from './constants';

type ContentProps = {};

interface ContentState{
  styles: object;
  position: object;
  tag: string;
  tagId: string;
  tagClass: string;
  fontFamily: string;
  fontSize: string;
  hidePopup: boolean;
}

class Content extends React.Component<ContentProps, ContentState> {
  inspector: DomInspector
  constructor(props: ContentProps) {
    super(props);
    this.state = {
      styles: {},
      position: {},
      tag: '',
      tagId: '',
      tagClass: '',
      fontFamily: '',
      fontSize: '',
      hidePopup: false,
    };
    this.inspector = new DomInspector({
      onMove: this.onCursorMove,
      exclude: ['#css-spider-root']
    });
    // enable by default
    this.inspector.enable();
  }

  onCursorMove = (styles: object, position: object, tag: string, tagId: string, tagClass: string, fontFamily: string, fontSize: string) => {
    this.setState({
      styles,
      position,
      tag,
      tagId,
      tagClass,
      fontFamily,
      fontSize,
      hidePopup: false,
    });
  }

  onPopupClose = () => {
    this.setState({
      hidePopup: true,
    });
  }

  componentWillUnmount() {
    try {
      this.inspector.destroy();
    } catch (e) {
      console.log('inspector not found');
    }
  }

  render() {
    return (
      <div className={'css-spider-container'}>
        <ActionsBar inspector={this.inspector} />
        {
          !this.state.hidePopup &&
          <Popup target={this.inspector.target}>
              <StylesContent
                element={this.inspector.target}
                values={this.state.styles}
                tag={this.state.tag}
                tagId={this.state.tagId}
                tagClass={this.state.tagClass}
                fontFamily={this.state.fontFamily}
                fontSize={this.state.fontSize}
                onClose={this.onPopupClose}
              />
            </Popup>
        }
      </div>
    );
  }
}

export default Content;

const renderContent = () => {
  if(!document.getElementById(cssSpiderRootName)) {
    const app = document.createElement('div');
    app.id = 'css-spider-root';
    document.body.appendChild(app);
    ReactDOM.render(<Content />, app);
  }
};

renderContent();


