
export const requiredValue = [
    'border-top-width',
    'border-right-width',
    'border-bottom-width',
    'border-left-width',
    'margin-top',
    'margin-right',
    'margin-bottom',
    'margin-left',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left',
    'z-index'
];

export const cssSpiderRootName = 'css-spider-root';